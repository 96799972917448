import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React from 'react';

import { AboutInfo, Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { BackgroundLines } from '../BackgroundLines';
import { SocialFooter } from '../Home/SocialFooter';
import { Text } from '../UI/Text';

import Silhouette from './bg.jpg';
import SilhouetteMobile from './bgMobile.png';

type Props = {
  aboutInfo: AboutInfo;
};

export function About({ aboutInfo }: Props) {
  return (
    <>
      <Container>
        <ImageContainer />
        <TextContainer>
          <CenteredText>
            <AboutText mode="about">{aboutInfo.Intro}</AboutText>
            <Services mode="social">{aboutInfo.Services}</Services>
            <ProposalLink href="mailto:email@arnopoppelaars.be">
              <Text mode="underline">Get a free proposal</Text>
            </ProposalLink>
            <Contact>
              <ContactBox>
                <TextAsDiv mode="gray">Let's get in touch</TextAsDiv>
                <LinkAsDiv href="mailto:email@arnopoppelaars.be">
                  <Text mode="social">{aboutInfo.Email}</Text>
                </LinkAsDiv>
                <LinkAsDiv href="tel:+32492633245">
                  <Text mode="social">{aboutInfo.Phone}</Text>
                </LinkAsDiv>
              </ContactBox>
            </Contact>
          </CenteredText>
        </TextContainer>
        <BottomLine />
        <BackgroundLinesContainer>
          <BackgroundLines />
        </BackgroundLinesContainer>
      </Container>
      <FooterContainer>
        <SocialFooter />
      </FooterContainer>
    </>
  );
}

const FooterContainer = styled.div`

  ${forBreakpoint(
  Breakpoint.TabletPortrait,
  css`
      margin-top: 18rem;
    `
)};

  ${forBreakpoint(
  Breakpoint.TabletLandscape,
  css`
      margin-top: 5rem;
    `
)};

   ${forBreakpoint(
  Breakpoint.Desktop,
  css`
      margin-top: 11rem;
    `
)};
`;

const BackgroundLinesContainer = styled.div`
  display: none;
`;

const Container = styled.div`
  position: relative;
  padding-top: 6rem;
  z-index: 1;

  ${forBreakpoint(
  Breakpoint.TabletLandscape,
  css`
      background-size: cover;
      background-image: url(${Silhouette});
      padding-bottom: 5rem;
    `
)};

  ${forBreakpoint(
  Breakpoint.Desktop,
  css`
      background-position-x: 20%;
    `
)};

  ${forBreakpoint(
  Breakpoint.UltraWide,
  css`
      padding-bottom: 16rem;
    `
)};
`;

const ContactBox = styled.div`
  display: inline-block;
  margin-top: 2rem;
  margin-right: 2rem;

  ${forBreakpoint(
  Breakpoint.TabletPortrait,
  css`
      margin-right: 3rem;
    `
)}

  ${forBreakpoint(
  Breakpoint.UltraWide,
  css`
      margin-right: 12rem;
    `
)}
`;

const TextAsDiv = styled(Text)`
  display: block;
`;

const LinkAsDiv = styled.a`
  display: block;
  cursor: pointer;
  text-decoration: none;
`;

const TextContainer = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;

  ${forBreakpoint(
  Breakpoint.TabletPortrait,
  css`
      margin-top: 2rem;
      max-width: 100vw;
    `
)}

  ${forBreakpoint(
  Breakpoint.TabletLandscape,
  css`
      width: 40vw;
      max-width: 22rem;
      margin-left: 1.5rem;
    `
)}

  ${forBreakpoint(
  Breakpoint.Desktop,
  css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 2rem;

      width: 40vw;
      max-width: 120rem;

      margin-left: 1.5rem;
      min-height: 60vh;
      vertical-align: center;
    `
)}
  
  ${forBreakpoint(
  Breakpoint.UltraWide,
  css`
      margin-top: 20rem;
      margin-left: 4rem;
    `
)}
`;

const CenteredText = styled.div`
  min-width: 40vw;
`;

const AboutText = styled(Text)`
  margin-bottom: 1.5rem;

  ${forBreakpoint(
  Breakpoint.TabletPortrait,
  css`
      margin-bottom: 2rem;
    `
)}
  ${forBreakpoint(
  Breakpoint.UltraWide,
  css`
      margin-bottom: 6rem;
    `
)}
`;

const Services = styled(Text)`
  margin-top: 1rem;
  margin-bottom: 0.7rem;
  ${forBreakpoint(
  Breakpoint.UltraWide,
  css`
      margin-bottom: 2.1rem;
    `
)}
`;

const ProposalLink = styled.a`
  display: block;
  cursor: pointer;
  margin-bottom: 2rem;
`;

const ImageContainer = styled.div`
  margin-bottom: 2rem;
  background-size: contain;
  width: 100%;
  height: 300px;
  background-image: url(${SilhouetteMobile});
  background-repeat: no-repeat;
  background-color: #f5f5f5;
  background-position: center;
  clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);

  ${forBreakpoint(
  Breakpoint.TabletLandscape,
  css`
      display: none;
    `
)};
`;

const Contact = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;

  ${forBreakpoint(
  Breakpoint.Phone,
  css`
      flex-direction: column;
      align-items: flex-start;
    `
)};

  ${forBreakpoint(
  Breakpoint.TabletPortrait,
  css`
      flex-direction: row;
    `
)};

  ${forBreakpoint(
  Breakpoint.Desktop,
  css`
      margin-top: 8rem;
    `
)};

  ${forBreakpoint(
  Breakpoint.UltraWide,
  css`
      margin-top: 35rem;
    `
)};
`;

const BottomLine = styled.div`
  min-height: 70px;
  background: white;
  z-index: 10;
  transform: rotate(-4deg);
  border-bottom: 1px solid rgba(112, 112, 112, 0.3);
  overflow-x: hidden;

  ${forBreakpoint(
  Breakpoint.TabletPortrait,
  css`
    background: white;
    min-height: 200px;
    margin-bottom: -10rem
    `
)};
  ${forBreakpoint(
  Breakpoint.TabletLandscape,
  css`
      display: none;
    `
)};
`;
