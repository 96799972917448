import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React, { Fragment, useEffect, useState } from 'react';

import { Breakpoint } from '../types';
import { forBreakpoint } from '../utils/forBreakpoint';

type Props = {
  shortVersion?: boolean;
};

export function BackgroundLines({ shortVersion = false }: Props) {
  const [windowWidth, setWindowWidth] = useState(0);

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  const maxX = windowWidth / (shortVersion ? 2 : 1.6);
  let xPositions = [2];

  let done = false;
  let exponent = 1.11;
  while (!done) {
    let currentMax = Math.max(...xPositions);

    if (currentMax > 200) {
      exponent = 1.07;
    }

    if (currentMax > 500) {
      exponent = 1.05;
    }

    let newVal = Math.pow(currentMax, exponent);

    if (newVal < maxX) {
      xPositions.push(newVal);
    } else {
      done = true;
    }
  }

  return (
    <StyledBackground>
      <Container>
        {xPositions.map((xPos, index) => {
          if (index > 6) {
            return (
              <Fragment key={xPos}>
                <StyledLine
                  left={xPos}
                  thick={index === xPositions.length - 1}
                />
              </Fragment>
            );
          }
        })}
      </Container>
    </StyledBackground>
  );
}

const Container = styled.div`
  margin-left: -26vw;

  @media screen and (min-width: 900px) {
    margin-left: -18vw;
  }

  @media screen and (min-width: 1200px) {
    margin-left: -16vw;
  }

  @media screen and (min-width: 1400px) {
    margin-left: -14vw;
  }

  @media screen and (min-width: 1800px) {
    margin-left: -12vw;
  }

  @media screen and (min-width: 2500px) {
    margin-left: -10vw;
  }
`;

const StyledLine = styled.div<{ left: number; thick?: boolean }>`
  opacity: ${props => (props.thick ? '0.4' : '0.15')};
  position: absolute;
  margin-left: ${props => props.left}px;
  margin-top: -2rem;
  width: 1px;
  height: 110vh;
  background: #5d5d5d;
  transform: rotate(17deg);
  overflow: hidden;

  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      width: 2px;
      margin-top: -10rem;
    `
  )}
`;

const StyledBackground = styled.div`
  z-index: -1;
  overflow: hidden;

  ${forBreakpoint(
    Breakpoint.TabletPortrait,
    css`
      display: block;
      width: 100%;
      height: 100%;
      visibility: visible;
      position: absolute;
      left: 0;
      top: 0;
    `
  )}

  ${forBreakpoint(
    Breakpoint.Phone,
    css`
      display: none;
    `
  )}
`;
