import { graphql } from 'gatsby';
import React from 'react';

import { About } from '../components/About/About';
import { Root } from '../components/Root';
import { AboutInfo } from '../types';

export const query = graphql`
  {
    sanityAbout {
      Email
      Intro
      Postal
      Phone
      Services
      Street
    }
  }
`;

export default ({ data }) => {
  const aboutInfo: AboutInfo = data.sanityAbout;
  return (
    <Root includeFooter={false}>
      <About aboutInfo={aboutInfo} />
    </Root>
  );
};
